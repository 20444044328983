@import "../../sass/variables.scss";

$bits-logo-width: 172px;
$bits-logo-height: 174px;

.bits-spinner-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .bits-spinner {
    color: $bits-color-total-black;
    position: absolute;
    width: $bits-logo-width;
    height: $bits-logo-height;
    border-width: 14px;
    animation: 1.5s linear infinite spinner-border;
  }

  .bits-cassette-spinner {
    color: $bits-color-total-black;
    position: absolute;
    width: $bits-logo-width;
    height: $bits-logo-height;
    border-width: 14px;
    animation: 1.5s linear infinite spinner-border;
  }


  .bits-spinner-logo {
    width: 132px;
    margin: 35px 0;
    font-weight: 600;
    color: $bits-color-total-black;
    font-size: 35px;
    letter-spacing: -1.1px;
    line-height: 1.2;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100px;
    }
  }

  @media only screen and (max-width: 767px) {
    $bits-logo-width: 68.71px;
    $bits-logo-height: 69.5px;
    .bits-spinner-logo,
    .bits-spinner,
    .bits-cassette-spinner {
      width: $bits-logo-width;
      height: $bits-logo-height;
    }

    .bits-spinner-logo {
      width: 48.71px;
      height: auto;
      margin: 12px 0;

      img {
        width: 40px;
      }
    }

    .bits-spinner,
    .bits-cassette-spinner {
      border-width: 5.6px;
    }
  }
}

.bits-spinner-message-wrapper {
  .bits-spinner-polygon {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .bits-spinner-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    border: 1px solid $bits-color-light-grey;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(159, 159, 159, 0.25);
    background: $bits-color-white;
  }

  .bits-cassette-spinner-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    border: 0px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px;
    border: 1px solid $bits-color-light-grey;
    background: $bits-color-white;
    color: $bits-color-light-purple;
  }

  i.bi-caret-down-fill {
    color: $bits-color-white;
    margin-top: -8px;
  }
}
